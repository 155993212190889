import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
  Button,

  Accordion,
  Card,
} from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LuteinComplexMobile from "./../../images/Prostatizal-plus-mobile.jpg"
import LuteinComplexTablet from "./../../images/Prostatizal-plus-tablet.jpg"
import LuteinComplexDesktop from "./../../images/Prostatizal-plus-desktop.jpg"
import LuteinComplexXL from "./../../images/Prostatizal-plus-desktop-xl.jpg"
import Feature1 from "./../../images/prost-plus-prostatitis-en.jpg"
import Feature2 from "./../../images/prost-plus-herbs.jpg"
import Feature3 from "./../../images/prost-plus-hazelnut.jpg"
import Feature4 from "./../../images/prost-psor-plus-herbal-guarantee.jpg"


const ProstatizalPlusPageEn = () => (
  <Layout backButton={true} enSite pageInfo={{ pageName: "ProstatizalPlusEn" }}>
    <Seo title="Prostatizal Plus - The solution to prostate problems" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/en">HOME</Link>
            </li>
            <Breadcrumb.Item active>PROSTATIZAL PLUS</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Prostatizal Plus"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
        <Col xs={6} className="d-none d-lg-block large-heading">
          <h5 className="text-uppercase font-light letter-space-wide ">
            Prostatizal Plus
          </h5>
          <h1 className="display-4 font-light">
            The solution to prostate problems
          </h1>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col sm={12} className="d-lg-none">
          <h4 className="text-uppercase mb-3 letter-space-wide">
            Prostatizal Plus
          </h4>
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            The solution to prostate problems
          </h1>
        </Col>
        <Col sm={12} md={6} className="mb-4">
          <h5 className="subhead font-light">
            Prostatisal Plus is a natural remedy for strengthening and restoring
            the activity of the prostate gland.
          </h5>
        </Col>
        <Col sm={12} md={6}>
          <Col className="mb-4 p-0">
            <div>
              <a
                href="https://bemore.shop/ena/prostatizal-plus-envh1006"
                target="blank"
                rel="nofollow"
                className="text-decoration-none"
              >
                <Button
                  variant="primary"
                  className="buy-button mx-auto d-block"
                >
                  Buy online at <strong>BeMore.shop</strong>
                </Button>
              </a>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <p>Available in pharmacies across the country</p>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <ul>
                <li>solvent free</li>
                <li>preservatives free</li>
                <li>gluten free</li>
                <li>sugar free</li>
                <li>GMO-free</li>
              </ul>
            </div>
          </Col>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Strengthen the prostate</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature1}
            alt="Strengthen the prostate"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Prostatisal Plus is a natural product that helps strengthen and
            restore the function of the prostate gland. Specially selected
            herbal extracts containing biologically active substances -
            phytosterols, saponins, tannins, organic acids, all of which support
            prostate health and the functions of the urinary and reproductive
            systems in men.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">
            In case of painful urination and inflammation
          </h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature2}
            alt="In case of painful urination and inflammation"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6}>
          <p>
            Hazelnut extract has diuretic, anti-inflammatory and
            vasoconstrictive effects. Small-flowered willow has a diuretic
            effect and has a beneficial effect on difficult, frequent and
            painful urination. In turn, the extract of thunderbolt,
            complementing the action of small-flowered willow, has a strong
            dehydrating effect and helps to eliminate the retention of urine in
            the bladder and to eliminate urinary tract infections. The
            biologically active substances extracted from calendula have
            antimicrobial and anti-inflammatory action.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">
            Beneficial effects on the urinary system
          </h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature3}
            alt="Beneficial effects on the urinary system"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Completely natural, it contains a standardized dry extract of the
            herbs hazel, small-flowered willow, thunder and calendula. The
            biologically active substances derived from these herbs have very
            good anti-inflammatory, diuretic, antispasmodic and analgesic
            effects. Prostatisal Plus has a beneficial effect on difficult,
            frequent and painful urination. Gradually eliminates the retention
            of urine in the bladder. It also helps to eliminate urinary tract
            infections, which are a common complication of prostate hyperplasia.
            Prostatisal Plus also helps to stimulate sexual energy in men.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Guarantee for quality and safety</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature4}
            alt="Guarantee for quality and safety"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6}>
          <p>
            Standardized plant extracts are obtained from plants or parts
            thereof by extraction of biologically active components. Their
            advantage is that the type of active substances can be controlled
            and maintained in a certain quantity and quality. The intake of an
            extract with a proven effect guarantees an optimal effect on the
            body's functions. The plant extracts included in Prostatizal Plus
            complement each other's beneficial effects.
          </p>
        </Col>
      </Row>

      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Additional information</h5>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col>
          <Accordion defaultActiveKey="0" className="acc-style">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="pointer"
              >
                Contents
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    <strong>Small-flowered willow</strong> – stalk (Extr. Hb.
                    Epilodium parviflorum) 70 mg
                  </p>
                  <p>
                    <strong>Hazelnut</strong> – bark (Extr. Cort. Corylus
                    avellanae) 60 mg
                  </p>
                  <p>
                    <strong>Thunderbolt</strong> – roots (Extr. Rad. Ononis
                    spinosa) 50 mg
                  </p>
                  <p>
                    <strong>Calendula</strong> – flower (Extr. Fl. Calendula
                    officinalis) 20 mg
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="pointer"
              >
                Intake
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>
                    Take 1 tablet Prostatizal Plus two times daily after meals,
                    no less than 3 months, without interruption.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="pointer"
              >
                Contraindications
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    None identified. Not recommended for people allergic to
                    wheat products. Prostatizal Plus is a dietary supplement and
                    does not cause irritation and hypersensitivity, and can be
                    taken for a long period. Prostatizal Plus can be taken alone
                    or in combination with medication prescribed by your doctor.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="pointer"
              >
                Effects
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Hazelnut extract has diuretic, anti-inflammatory and
                    vasoconstrictive effects. Small-flowered willow has a
                    diuretic effect and has a beneficial effect on difficult,
                    frequent and painful urination. In turn, the extract of
                    thunderbolt, complementing the action of small-flowered
                    willow has a strong dehydrating effect and helps to
                    eliminate the retention of urine in the bladder and to
                    eliminate urinary tract infections. The biologically active
                    substances extracted from calendula have antimicrobial and
                    anti-inflammatory action.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ProstatizalPlusPageEn
